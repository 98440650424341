<template>
  <div class="cultures-component">
    <div
      class="block5"
      :data-src="'../../../assets/imgs/home/Group_920@2x.png'"
      :style="{ 'padding-top': this.pageLang === 'zh' ? '9.735vw' : '9vw' }"
    >
      <div class="row-1">
        <div class="col-2">
          <div class="container-5">
            <p
              class="title-5"
              :style="{
                'font-size': this.pageLang === 'zh' ? '2.196vw' : none,
                'letter-spacing': this.pageLang === 'zh' ? '0.132vw' : none,
              }"
            >
              {{ $t("message.culture.vision") }}
            </p>
          </div>
          <div
            class="content-5 f-f-sans"
            :style="{
              'font-size': this.pageLang === 'zh' ? '1.903vw' : none,
            }"
          >
            <p>{{ $t("message.culture.content-2") }}</p>
          </div>
        </div>

        <div
          class="col-1"
          :style="{
            'margin-top': this.pageLang === 'zh' ? '7.247vw' : none,
          }"
        >
          <div class="container-5 container-5-low">
            <p
              class="title-5"
              :style="{
                'font-size': this.pageLang === 'zh' ? '2.196vw' : none,
                'letter-spacing': this.pageLang === 'zh' ? '0.132vw' : none,
              }"
            >
              {{ $t("message.culture.mission") }}
            </p>
          </div>

          <div
            class="content-5 f-f-sans"
            :style="{
              'font-size': this.pageLang === 'zh' ? '1.903vw' : none,
            }"
          >
            <p>{{ $t("message.culture.content-1") }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  data() {
    return {
      pageLang: "123",
    };
  },
  computed: {
    ...mapState(["lang"]),
  },

  watch: {
    lang(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.pageLang = newValue;
        //console.log(newValue);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@font-face {
  font-family: "SemiRaleway";
  src: url("../../../assets/custom-font/Raleway-SemiBold.ttf");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}
@import "./SharedStyle.scss";
@mixin styling($unit-size) {
  .cultures-component {
    .block5 {
      padding: 123 * $unit-size 282 * $unit-size 0 265 * $unit-size;
      display: flex;
      flex-direction: column;
      height: (651-117.23) * $unit-size;
      background-image: url("../../../assets/imgs/home/Group_920@2x.png"),
        url("../../../assets/imgs/home/bady-abbas-MDgRcuGYu58-unsplas@2x.png");
      background-color: white;
      background-position: bottom, center;
      background-repeat: no-repeat;
      background-size: 831.5 * $unit-size 162.11 * $unit-size, 100% 100%;

      .row-1 {
        height: 212.49 * $unit-size;
        display: flex;
        flex-direction: column;

        .container-5 {
          display: flex;
          flex-direction: row;
          justify-content: center;
          height: 4.7rem;
          text-align: center;

          .title-5 {
            color: var(--unnamed-color-000000);
            height: 53 * $unit-size;
            text-align: center;
            font-size: 45 * $unit-size;
            font-family: SemiRaleway;
            font-weight: 600;
            line-height: 53 * $unit-size;
            color: rgba(0, 0, 0, 1);
          }
        }

        .content-5 {
          margin-top: 10 * $unit-size;
          font-size: 30 * $unit-size;
          line-height: 41 * $unit-size;
          text-align: center;
          font-weight: 400;
          height: 28 * $unit-size;
          letter-spacing: 0px;
          color: rgba(0, 0, 0, 1);
          white-space: nowrap;
        }

        .col-1 {
          margin-left: auto;
          margin-right: auto;
          margin-top: 93 * $unit-size;
          height: 219.14 * $unit-size;
          // background-color: white;
          background-position: 0% 100%;
          background-repeat: no-repeat;
          background-size: 70%;
        }

        .col-2 {
          margin-left: auto;
          margin-right: auto;
          height: 219.14 * $unit-size;

          background-position: 0% 80%;
          background-repeat: no-repeat;
          background-size: 50%;
        }
      }
    }
  }
}
@media (max-width: 100vw) {
  @include styling($unit-size: 100vw/1366);
}
</style>