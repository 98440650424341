<template>
  <div class="about">
    <div class="background">
      <div class="block2-row-1">
        <div class="column-1">
          <div class="flex-row">
            <div class="ID f-f-ptsans">03</div>
            <div class="title">
            {{ $t("message.about-title") }}
          </div>
          </div>
          <div class="title-underline"></div>

          
        </div>
        <div class="column-2">
          <p class="content-head f-f-raleway">
            {{ $t("message.about-title") }}
          </p>
        </div>
        <div class="column-3"></div>
      </div>

      <div class="content">
        <p>
          {{ $t("message.about") }}
        </p>
      </div>
      <div class="button" @click="goto('about-us')">
        <p>
          {{ $t("message.about-button") }}
          <i
            style="margin-left: 10%; font-size: 1.5rem"
            class="iconfont iconjiantou1"
          ></i>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    goto(where) {
      this.$router.push("/" + where);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "./SharedStyle.scss";

@mixin styling($unit-size) {
  .about {
    .flex-row{
      display: flex;
      flex-direction: row;
    }
    //padding: 17.6rem 0 0 0;
    height: 727 * $unit-size;

    .background {
      .block2-row-1 {
        display: flex;
        flex-flow: row;

        .column-1 {
          width: 30%;
          flex-flow: column;
          height: 128 * $unit-size;

          .ID {
            margin-left: 100 * $unit-size;
            margin-top: 77 * $unit-size;
            font-size: 28 * $unit-size;
            font-family: PT Sans;
            color: black;
          }

          .title-underline {
            margin-left: 100 * $unit-size;
            margin-top: 7 * $unit-size;
            border-bottom: solid rgba(217, 217, 217, 1) 1px;
            width: 140 * $unit-size;
          }

          .title {
            margin-left: 16 * $unit-size;
            margin-top: 89 * $unit-size;
            font-size: 22 * $unit-size;
            color: rgba(172, 169, 169, 1);
            
          }
        }

        .column-2 {
          width: 40%;
          height: 128 * $unit-size;

          .content-head {
            color: var(--unnamed-color-000000);
            height: 22 * $unit-size;
            text-align: center;
            font: Raleway;
            font-size: 22 * $unit-size;
            line-height: 26 * $unit-size;
            letter-spacing: 0.88 * $unit-size;
            font-weight: 800;
            position: relative;
            margin-top: 106 * $unit-size;
          }
        }

        .column-3 {
          width: 30%;
          height: 128 * $unit-size;
        }
      }

      //padding: 14rem 14rem 0 14rem;
      display: flex;
      flex-direction: column;

      height: 100%;
      background-image: url("../../../assets/imgs/home/about-us.png");
      background-color: #eaf3ff;
      background-position: 0 100%;
      background-repeat: no-repeat;
      background-size: 100% auto;
      //position: relative;
    }

    .img {
      margin-top: 40rem;
      margin-left: 0rem;

      width: 50.5rem;
      height: 38.5rem;
      transform: scale(0.7);
      transform-origin: bottom left;
      transition: 1s;
    }

    .content {
      margin-left: auto;
      margin-right: auto;
      white-space: pre-line;

      margin-top: 27 * $unit-size;
      line-height: 35 * $unit-size;

      width: 754 * $unit-size;
      height: 39%;

      color: var(--unnamed-color-000000);
      text-align: center;
      font-family: Arial;
      font-size: 16 * $unit-size;
      letter-spacing: 0.32 * $unit-size;

      color: #000000;
      opacity: 1;
      .read-more {
        &:hover {
          border-bottom: solid;
        }
      }
    }
    .button {
      cursor: pointer;
      margin-left: auto;
      margin-right: auto;
      margin-top: 5 * $unit-size;

      width: 154 * $unit-size;
      height: 40 * $unit-size;
      line-height: 40 * $unit-size;
      text-align: center;
      color: black;
      border: solid black 1px;
      border-radius: 4px;
      font-size: 14 * $unit-size;
      font-family: Helvetica;
      .arrow {
        margin-left: 12 * $unit-size;
        width: 19.73 * $unit-size;
        height: 15.62 * $unit-size;
      }
    }
  }
}

@media (max-width: 100vw) {
  @include styling($unit-size: 100vw/1366);
}
</style>