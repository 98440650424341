<template>
  <div class="intro">
    <div class="container-1-left">
      <div class="block-1-message">
        <p>{{ $t("message.welcome") }}</p>
      </div>

      <div>
        <div class="flex-row">
          <div class="ID f-f-ptsans">01</div>
          <div class="title">
          {{ $t("message.intro-title") }}
        </div>
        </div>
        
        <div class="title-underline"></div>

        
      </div>

      <div class="subtitle-s" style="text-align: center">
        <div class="subtitle" @mouseover="consultation()">
          <svg class="small-icon" viewBox="0 0 3.367vw 3.367vw">
            <ellipse
              style="stroke: #e3e3e3"
              v-if="selected === 1"
              id="Ellipse_179"
              rx="1.65vw"
              ry="1.65vw"
              cx="1.6835vw"
              cy="1.6835vw"
            ></ellipse>

            <ellipse
              id="Ellipse_179"
              rx="0.25vw"
              ry="0.25vw"
              cx="1.6835vw"
              cy="1.6835vw"
            ></ellipse>
          </svg>
          <span
            @click="goto('urban-design')"
            v-if="selected === 1"
            style="color: black; cursor: pointer"
            >{{ $t("message.intro-subtitle-1") }}</span
          >
          <span v-else>{{ $t("message.intro-subtitle-1") }}</span>
        </div>

        <div
          class="subtitle"
          @mousemove="realestate()"
          style="margin-top: 2.892vw"
        >
          <svg class="small-icon" viewBox="0 0 3.367vw 3.367vw">
            <ellipse
              style="stroke: #e3e3e3"
              v-if="selected === 2"
              id="Ellipse_179"
              rx="1.65vw"
              ry="1.65vw"
              cx="1.6835vw"
              cy="1.6835vw"
            ></ellipse>

            <ellipse
              id="Ellipse_179"
              rx="0.25vw"
              ry="0.25vw"
              cx="1.6835vw"
              cy="1.6835vw"
            ></ellipse>
          </svg>

          <span
            v-if="selected === 2"
            style="color: black; cursor: pointer"
            @click="goRealEstate()"
            >{{ $t("message.intro-subtitle-2") }}</span
          >

          <span v-else>{{ $t("message.intro-subtitle-2") }}</span>
        </div>

        <div
          class="subtitle"
          @mouseover="construction()"
          style="margin-top: 2.562vw"
        >
          <svg class="small-icon" viewBox="0 0 3.367vw 3.367vw">
            <ellipse
              style="stroke: #e3e3e3"
              v-if="selected === 3"
              id="Ellipse_179"
              rx="1.65vw"
              ry="1.65vw"
              cx="1.6835vw"
              cy="1.6835vw"
            ></ellipse>

            <ellipse
              id="Ellipse_179"
              rx="0.25vw"
              ry="0.25vw"
              cx="1.6835vw"
              cy="1.6835vw"
            ></ellipse>
          </svg>

          <span
            style="color: black; cursor: pointer"
            v-if="selected === 3"
            @click="goConstruction()"
            >{{ $t("message.intro-subtitle-3") }}</span
          >

          <span v-else>{{ $t("message.intro-subtitle-3") }}</span>
        </div>
      </div>
    </div>

    <div class="container-1-right">
      <div class="content">
        <div v-if="selected === 1" style="height: 100%">
          <div
            v-if="mouseover === true"
            @mouseleave="clear"
            class="image-blur image-1-blur"
          >
            <div>
              <p style="margin-top: 10vw">
                {{ $t("message.urban-design.shortDescription") }}
              </p>

              <button class="button" @click="goto('urban-design')">
                <p :style="{ 'margin-left': lang === 'zh' ? '17%' : '13%' }">
                  {{ $t("message.read-more") }}
                  <i
                    class="iconfont iconjiantou1"
                    style="margin-left: 40%; margin-top: 10%; font-size: 1rem"
                  ></i>
                </p>
              </button>
            </div>
          </div>
          <div v-else class="image image-1" @mouseover="blur"></div>
        </div>

        <div v-else-if="this.selected === 2" style="height: 100%">
          <div
            v-if="mouseover === true"
            @mouseleave="clear"
            class="image-blur image-2-blur"
          >
            <div style="height: 100%" class="flex-column">
              <p :style="{ 'margin-top': lang === 'zh' ? '20%' : '15%' }">
                {{ $t("message.property-dev.shortDescription") }}
              </p>

              <button class="button" @click="goRealEstate()">
                <p :style="{ 'margin-left': lang === 'zh' ? '17%' : '13%' }">
                  {{ $t("message.read-more") }}
                  <i
                    class="iconfont iconjiantou1"
                    style="margin-left: 40%; margin-top: 10%; font-size: 1rem"
                  ></i>
                </p>
              </button>
            </div>
          </div>
          <div v-else class="image image-2" @mouseover="blur"></div>
        </div>
        <div v-else-if="selected === 3" style="height: 100%">
          <div
            v-if="mouseover === true"
            @mouseleave="clear"
            class="image-blur image-3-blur"
          >
            <div>
              <p :style="{ 'margin-top': lang === 'zh' ? '20%' : '15%' }">
                {{ $t("message.construction.shortDescription") }}
              </p>
            </div>

            <button class="button" @click="goConstruction()">
              <p :style="{ 'margin-left': lang === 'zh' ? '17%' : '13%' }">
                {{ $t("message.read-more") }}
                <i
                  class="iconfont iconjiantou1"
                  style="margin-left: 40%; margin-top: 10%; font-size: 1rem"
                ></i>
              </p>
            </button>
          </div>
          <div v-else class="image image-3" @mouseover="blur"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data: function () {
    return {
      selected: 1,
      mouseover: false,
      scrollTop: 0,
    };
  },

  methods: {
    goto(where) {
      this.$router.push("/" + where);
    },

    blur: function () {
      this.mouseover = true;
    },

    clear: function () {
      this.mouseover = false;
    },

    goRealEstate: function () {
      this.$router.push("/property-dev");
    },

    goConstruction: function () {
      this.$router.push("/construction");
    },

    consultation: function () {
      this.selected = 1;
    },
    realestate: function () {
      this.selected = 2;
    },
    construction: function () {
      this.selected = 3;
    },
  },
};
</script>

<style lang='scss' scoped>
@import "./SharedStyle.scss";
@mixin styling($unit-size) {
  .intro {
    padding: 77 * $unit-size 101 * $unit-size 0 100 * $unit-size;

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    position: relative;
    //background-color: red;

    .ID {
      margin-top: 95 * $unit-size;
      // background-color: green;
    }

    .title-underline {
      margin-top: 6.18 * $unit-size;
      width: 165 * $unit-size;
      border-color: rgba(217, 217, 217, 1);
    }

    .title {
      //margin-top: 9.82 * $unit-size;
      // background-color: red;
      margin-top: 103 * $unit-size;
      margin-left: 16 * $unit-size;
      height: 22 * $unit-size;
      line-height: 22 * $unit-size;
      font-size: 22 * $unit-size;
      color: #ACA9A9;
    }
  }

  .flex-row{
    display: flex;
    flex-direction: row;
  }

  .container-1-left {
    display: flex;
    flex-direction: column;
    width: (46+9+338) * $unit-size;
    //min-width: 55rem;

    .block-1-message {
      position: relative;
      top: -20 * $unit-size;
      //background-color: red;
      font-family: Helvetica;
      font-size: 16 * $unit-size;
      white-space: pre-line;
      line-height: 20 * $unit-size;
      height: 39 * $unit-size;
      color: black;
    }

    .subtitle-s {
      display: flex;
      flex-direction: column;
      //width: 50rem;
      margin-top: 69.5 * $unit-size;
      margin-bottom: 31 * $unit-size;

      .subtitle {
        width: 393 * $unit-size;
        display: flex;
        flex-direction: row;
        height: 46 * $unit-size;

        &:hover {
          color: var(--unnamed-color-000000);
        }

        //margin-left: 5rem;

        text-align: left;

        .small-icon {
          //margin-top: 4rem;

          width: 48 * $unit-size;
          height: 48 * $unit-size;
          opacity: 1;
          fill: transparent;
          stroke: rgba(59, 125, 216, 1);
          stroke-width: 1px;
          stroke-linejoin: miter;
          stroke-linecap: butt;
          stroke-miterlimit: 4;
          shape-rendering: auto;
        }

        span {
          font-family: Raleway;
          font-weight: 800;
          font-size: $unit-size * 28;
          line-height: $unit-size * 33;
          margin-left: $unit-size * 9;
          margin-top: $unit-size * 9;
          letter-spacing: $unit-size * 0.22;
          white-space: nowrap;
        }
      }

      text-align: left;

      color: rgba(227, 227, 227, 1);
    }
  }

  .container-1-right {
    display: flex;
    flex-direction: column;
    width: $unit-size * 748;
    height: 467 * $unit-size;
    margin-bottom: 0;
    position: absolute;
    bottom: 0;
    right: 101 * $unit-size;

    .content {
      display: flex;
      flex-direction: column;
      position: relative;

      height: 100%;

      line-height: 20 * $unit-size;

      p {
        margin-left: auto;
        margin-right: auto;
        margin-top: 10 * $unit-size;
        text-align: center;
        font-size: 15 * $unit-size;
        color: white;

        width: 50%;
      }

      .button {
        // cursor: pointer;
        margin-left: auto;
        margin-right: auto;
        text-align: center;

        display: flex;
        flex-direction: row;
        justify-content: space-around;

        background-color: rgba(74, 145, 242, 1);
        margin-top: 50 * $unit-size;
        width: 22 * 6 * $unit-size;
        height: 5.4 * 6 * $unit-size;
        border: 1px solid var(--unnamed-color-000000);

        border-radius: 4px;
        opacity: 1;
        line-height: 5.4 * 6 * $unit-size;

        p {
          margin-top: 0;
          white-space: nowrap;
          font-size: 10 * $unit-size;
          line-height: 5.4 * 6 * $unit-size;
          height: 5.4 * 6 * $unit-size;
        }
      }

      .image-blur {
        position: relative;
        display: flex;
        flex-direction: column;

        width: auto;
        height: 100%;
        background-color: white;
        background-position: bottom;
        background-repeat: no-repeat;
        background-size: auto 100%;
      }

      .image-1-blur {
        background-image: linear-gradient(
            rgba(0, 0, 0, 0.25),
            rgba(0, 0, 0, 0.25)
          ),
          // url("../../../assets/imgs/home/F_01.jpg");
          url("../../../assets/images/urban-design/danga/F_01.gif");
      }

      .image-2-blur {
        background-image: linear-gradient(
            rgba(0, 0, 0, 0.5),
            rgba(0, 0, 0, 0.5)
          ),
          url("../../../assets/imgs/home/realestate.jpg");
      }

      .image-3-blur {
        background-image: linear-gradient(
            rgba(0, 0, 0, 0.5),
            rgba(0, 0, 0, 0.5)
          ),
          // url("../../../assets/imgs/home/construction.jpg");
          url("../../../assets/images/constructions/mandai/rain-forest.gif");
        background-color: white;
      }
      .image {
        width: auto;
        height: 100%;
        background-color: white;
        background-position: bottom;
        background-repeat: no-repeat;
        background-size: auto 100%;
      }

      .image-1 {
        // background-image: url("../../../assets/imgs/home/F_01.jpg");
        background-image: url("../../../assets/images/urban-design/danga/F_01.gif");
      }

      .image-2 {
        background-image: url("../../../assets/imgs/home/realestate.jpg");
      }

      .image-3 {
        // background-image: url("../../../assets/imgs/home/construction.jpg");
        background-image: url("../../../assets/images/constructions/mandai/rain-forest.gif");
      }

      font-size: 2rem;
    }
  }
}

@media (max-width: 100vw) {
  @include styling($unit-size: 100vw/1366);
}
</style>