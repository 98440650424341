<template>
    <div class="news-component">
        <div class="row-1">
            <div class="col-1">
                <div class="flex-row">
                    <div class="id f-f-ptsans">02</div>
                    <div class="subtitle">{{ $t("message.news.title") }}</div>
                </div>
                
                <div class="underline"></div>
                
            </div>
            <div class="col-2"></div>
            <div class="col-3"></div>
        </div>

        <div class="row-2">
            <div class="news-1 flex-row">
                <div class="left">
                    <img
                        loading="lazy"
                        :src="`${this.allNews[0].articleCoverUrl}`"
                        alt=""
                    />
                </div>
                <div class="right">
                    <div class="p-1">{{ $t("message.news.title1") }}</div>
                    <div class="p-2">
                        {{ this.allNews[0].articleTitle }}
                    </div>
                    <div class="p-3">{{  this.allNews[0].createTime | formatDate }}</div>
                    <div class="p-4" v-html="this.allNews[0].articleContent"></div>
                    ...

                    <div class="functions flex-row">
                        <div class="button" @click="goNews(allNews[0].id)">
                            {{ $t("message.news.button1") }}
                        </div>
                        <div class="icons flex-row">
                            <ShareNetwork
                                network="facebook"
                                title="I'd like to share this news with you."
                                :url="currentUrl"
                            >
                                <div class="icon-1 icon">
                                    <i
                                        style="font-size: 1.7rem"
                                        class="iconfont iconfacebook"
                                    ></i>
                                </div>
                            </ShareNetwork>
                            <ShareNetwork
                                network="twitter"
                                title="I'd like to share this news with you."
                                :url="currentUrl"
                            >
                                <div class="icon-3 icon">
                                    <i
                                        style="font-size: 1.8rem"
                                        class="iconfont icontweeter"
                                    ></i>
                                </div>
                            </ShareNetwork>
                            <ShareNetwork
                                network="weibo"
                                title="I'd like to share this news with you."
                                :url="currentUrl"
                            >
                                <div class="icon-2 icon">
                                    <i
                                        style="font-size: 2.3rem"
                                        class="iconfont icon-weibo"
                                    ></i>
                                </div>
                            </ShareNetwork>
                        </div>
                        
                    </div>
                </div>
            </div>
            <div class="news-2 news" @click="goNews(allNews[1].id)">
                <div class="top">
                    <div class="date">
                        <div class="day">{{ this.allNews[1].createTime | formatDay}}</div>
                        <div class="year_month">{{ this.allNews[1].createTime | formatYearMonth}}</div>
                    </div>
                    <img loading="lazy" :src="`${ this.allNews[1].articleCoverUrl}`" />
                </div>
                <div class="bottom">
                    <div class="title">
                        {{ this.allNews[1].articleTitle }}
                    </div>
                </div>
            </div>
            <div class="news-3 news" @click="goNews(allNews[2].id)">
                <div class="top">
                    <div class="date">
                        <div class="day">{{  this.allNews[2].createTime | formatDay}}</div>
                        <div class="year_month">{{ this.allNews[2].createTime | formatYearMonth}}</div>
                    </div>
                    <img loading="lazy" :src="`${ this.allNews[2].articleCoverUrl}`" />
                </div>
                <div class="bottom">
                    <div class="title">
                        {{ this.allNews[2].articleTitle }}
                    </div>
                </div>
            </div>
            <div class="news-4 news " @click="goNews(allNews[3].id)">
                <div class="top">
                    <div class="date">
                        <div class="day">{{  this.allNews[3].createTime | formatDay}}</div>
                        <div class="year_month">{{ this.allNews[3].createTime | formatYearMonth}}</div>
                    </div>
                    <img loading="lazy" :src="`${ this.allNews[3].articleCoverUrl}`" />
                </div>
                <div class="bottom">
                    <div class="title">
                        {{ this.allNews[3].articleTitle }}
                    </div>
                </div>
            </div>
        </div>

        <div class="row-3">
            <div class="button" @click="goAllNews()">
                <p>
                    {{ $t("message.news.button2") }}
                    <i
                        style="margin-left: 10%; font-size: 1.5rem"
                        class="iconfont iconjiantou1"
                    ></i>
                </p>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState } from "vuex";
import { formatDate } from '@/common/date.js' // 在组件中引用date.js
export default {
    data() {
        return {
            newsID: null,
            photos: [],
            allNews: [],
            currentUrl: "",
            init: {
                language_url:
                'https://cdn.jsdelivr.net/gh/wt-sml/wutong_cdn/js/tinymce-lang-zh@5.2.2.js',
                language: 'zh_CN',
                height: 600,
                plugins: 'autoresize',
                toolbar: '',
                branding: false,
                menubar: false,
                statusbar: false,
                resize: false,                
            },
        };
    },

    filters: {
        formatDay(time) {
            var date = new Date(time);
            return formatDate(date, 'dd');
        },

        formatYearMonth(time) {
            var date = new Date(time);
            return formatDate(date, 'yyyy-MM');
        },

        formatDate(time) {
            var date = new Date(time);
            return formatDate(date, 'yyyy-MM-dd');
        }
    },

    created() {
        let currentLang = 1;
        if (this.$store.state.lang === "zh") {
            currentLang = 0;
        } else {
            currentLang = 1;
        }
        this.getAllNews(currentLang);
    },

    computed: {
        userStyle() {
            return {};
        },

        ...mapState(["lang"]),
    },

    watch: {
        lang(newValue, oldValue) {
            if (newValue !== oldValue) {
                if (newValue === "zh") {
                    this.getAllNews(0);
                } else {
                    this.getAllNews(1);
                }
            }
        },
    },

    methods: {
        goNews: function (id) {
            this.$router.push("/news/?id=" + id);
            window.scrollTo(0, 0);
        },

        goAllNews: function () {
            this.$router.push("/all-news");
        },

        goto(where) {
            this.$router.push("/" + where);
        },

        async getAllNews(languageType) {
            let response = await this.$http.get(
                `${this.$url.memo.url_memo_public}?pageNum=${1}&pageSize=${10}&types=2`
            );
            this.allNews = response.data.list;
            this.currentUrl =
                "http://www.mcc.sg/news/?id=" + response.data.list[0].id;

            this.content = require("../../../assets/homepage/content.json");
            if (this.allNews.length !== 0) {
                for (let index = 0; index < this.allNews.length; index++) {
                    if (languageType===1) {
                        this.allNews[index].articleTitle = this.allNews[index].articleTitleEn;
                        this.allNews[index].articleContent = this.allNews[index].articleContentEn;
                    }
                    else {
                        this.allNews[index].articleTitle = this.allNews[index].articleTitleCn;
                        this.allNews[index].articleContent = this.allNews[index].articleContentCn;
                    }  
                    if (this.allNews[index].articleContent !== null) {
                        this.allNews[index].articleContent = this.allNews[index].articleContent.replace(/<.*?>/g,"");
                    }
                    // console.log(this.allNews)
                }
            } else {
                //pending chinese version
            }
            // console.log(this.allNews)
        },

        async getNews(id, sortType, languageType) {
            let response = await this.$http.get(
                `${this.$url.memo.url_memo_detail}?id=${id}&sortType=${sortType}`
            );
            let news = response.data;
            if (languageType===1) {
                news.articleTitle = news.articleTitleEn;
                news.articleContent = news.articleContentEn;
            }
            else {
                news.articleTitle = news.articleTitleCn;
                news.articleContent = news.articleContentCn;
            }
        },
    },
};
</script>

<style lang="scss">
.tox.tox-tinymce.tox-tinymce--disabled {
  border: none;
}
.tox-notification.tox-notification--warning {
  display: none !important;
}
</style>


<style lang="scss" scoped>
@import "./SharedStyle.scss";
@mixin styling($unit-size) {
    .news-component {
        .flex-row{
            display: flex;
            flex-direction: row;
        }
        //background-color: yellow;
        padding-left: 100 * $unit-size;
        padding-right: 100 * $unit-size;
        padding-top: 60 * $unit-size;
        padding-bottom: 90 * $unit-size;
        .row-1 {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            .col-1 {
                width: 177 * $unit-size;
                .id {
                    font-family: PT Sans;
                    font-size: 26 * $unit-size;
                    font-weight: 500;
                    letter-spacing: 1.12 * $unit-size;
                    line-height: 37 * $unit-size;
                }
                .subtitle {
                    font-family: Helvetica;
                    font-size: 20 * $unit-size;
                    line-height: 28 * $unit-size;
                    height: 12 * $unit-size;
                    margin-top: 7 * $unit-size;
                    margin-left: 16 * $unit-size;
                    font-weight: Medium;
                    white-space: nowrap;
                    color: #ACA9A9;
                }

                .underline {
                    height: 1px;
                    width: 283 * $unit-size;
                    margin-top: 11.31 * $unit-size;
                    border-bottom: solid rgba(217, 217, 217, 1) 1px;
                    border-width: 1px;
                    width: 310 * $unit-size;
                }
            }
            .col-2 {
                margin-top: 4 * $unit-size;
                text-align: center;
                color: #1f76ad;
                font-family: Raleway;
                font-size: 22 * $unit-size;
                font-weight: 800;
            }
            .col-3 {
                width: 177 * $unit-size;
            }
        }
        .row-2 {
            margin-top: 23 * $unit-size;
            font-family: Arial;

            .news-1 {
                width: 100%;
                height: 430 * $unit-size;

                .left {
                    width: 760 * $unit-size;
                    height: 100%;
                    margin-right: 15 * $unit-size;
                    //background-color: red;
                    img {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                        border-color: rgba(0,0,0,.05);
                        border-width: 1px;
                        border-style: solid;
                    }
                }
                .right {
                    padding-left: 24 * $unit-size;
                    padding-right: 24 * $unit-size;
                    width: 424 * $unit-size;
                    height: 100%;
                    background-color: #EAF3FF;
                    .p-1 {
                        margin-top: 24 * $unit-size;
                        font-size: 14 * $unit-size;
                        height: 16 * $unit-size;
                        line-height: 16 * $unit-size;
                    }
                    .p-2 {
                        font-size: 18 * $unit-size;
                        font-weight: 600;
                        max-height: 44 * $unit-size;
                        line-height: 22 * $unit-size;
                        margin-top: 24 * $unit-size;
                        letter-spacing: -0.4 * $unit-size;
                        height: 22* 2 * $unit-size;
                        overflow: hidden;
                    }
                    .p-3 {
                        margin-top: 8 * $unit-size;
                        height: 14 * $unit-size;
                        line-height: 14 * $unit-size;
                        font-size: 12 * $unit-size;
                        color: rgba(134, 134, 134, 1);
                    }
                    .p-4 {
                        font-size: 18 * $unit-size;
                        line-height: 24 * $unit-size;
                        margin-top: 32 * $unit-size;
                        height: 24 * 7 * $unit-size;
                        text-align: justify;
                        overflow: hidden;
                        // background-color: yellow;
                        
                    }
                    .functions {
                        justify-content: space-between;
                        margin-top: 44 * $unit-size;
                        a {
                            text-decoration: none !important;
                            outline: none;
                        }
                        .button {
                            display: flex;
                            flex-direction: row;
                            justify-content: space-around;
                            text-align: center;
                            cursor: pointer;
                            width: 92 * $unit-size;
                            height: 32 * $unit-size;
                            background-color: rgba(74, 145, 242, 1);
                            border-radius: 4px;
                            font-size: 14 * $unit-size;
                            line-height: 30 * $unit-size;
                            color: white;
                        }
                        .icon {
                            color: black;
                            margin-top: 10 * $unit-size;
                            cursor: pointer;
                        }
                        .icon-2 {
                            margin-top: 8.5 * $unit-size;
                            margin-left: 6 * $unit-size;
                        }
                        .icon-3 {
                            margin-left: 6 * $unit-size;
                        }
                        
                    }
                }
            }
            .news {
                cursor: pointer;
                height: 267 * $unit-size;
                display: inline-block;
                // padding-right: 10 * $unit-size;
                // margin: auto;
                margin-top: 20 * $unit-size;
                margin-right: 16 * $unit-size;
                &:last-child {margin-right: 0;}

                .top {
                    width: 376 * $unit-size;
                    height: 80%;
                    // background-color: blue;
                    img {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;

                        border-color: rgba(0,0,0,.05);
                        border-width: 1px;
                        border-style: solid;
                    }
                    .date {
                        width: 59 * $unit-size;
                        height: 49 * $unit-size;
                        color: white;
                        background-color: rgb(127, 169, 223);
                        position: absolute;
                        opacity: 0.9;
                        .day{
                            font-size: 24 * $unit-size;
                            margin-top: 8 * $unit-size;
                            text-align: center;
                            white-space: nowrap;
                        }
                        .year_month{
                            font-size: 12 * $unit-size;
                            margin-top: 1 * $unit-size;
                            text-align: center;
                            white-space: nowrap;
                        }                    
                    }
                }
                
                .bottom {
                    width: 376 * $unit-size;
                    height: 20%;
                    // background-color: red;
                    .title {
                        height: 100%;
                        width: 366 * $unit-size;
                        font-weight: 800;
                        font-size: 16 * $unit-size;
                        margin-left: 10 * $unit-size;
                        // margin-top: 5 * $unit-size;
                        line-height: 28 * $unit-size;
                        color: rgba(0,0,0,0.85);
                        overflow: hidden;
                    }
                }
                
                
            }
        }
        .row-3 {
            height: 40 * $unit-size;
            //background-color: green;
            margin-top: 22 * $unit-size;
            .button {
                cursor: pointer;
                margin-left: auto;
                margin-right: auto;

                width: 153 * $unit-size;
                height: 40 * $unit-size;
                line-height: 40 * $unit-size;
                text-align: center;
                color: white;
                background-color: #5497F2;
                border-radius: 2px;
                font-size: 14 * $unit-size;
                .arrow {
                    margin-left: 12 * $unit-size;
                    width: 17.66 * $unit-size;
                    height: 15.61 * $unit-size;
                    filter: invert(100%) sepia(33%) saturate(2%)
                        hue-rotate(161deg) brightness(111%) contrast(100%);
                }
            }
        }
    }
}
@media (max-width: 100vw) {
    @include styling($unit-size: 100vw/1366);
}
</style>
    
