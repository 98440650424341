var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"cultures-component"},[_c('div',{staticClass:"block5",style:({ 'padding-top': this.pageLang === 'zh' ? '9.735vw' : '9vw' }),attrs:{"data-src":'../../../assets/imgs/home/Group_920@2x.png'}},[_c('div',{staticClass:"row-1"},[_c('div',{staticClass:"col-2"},[_c('div',{staticClass:"container-5"},[_c('p',{staticClass:"title-5",style:({
              'font-size': this.pageLang === 'zh' ? '2.196vw' : _vm.none,
              'letter-spacing': this.pageLang === 'zh' ? '0.132vw' : _vm.none,
            })},[_vm._v(" "+_vm._s(_vm.$t("message.culture.vision"))+" ")])]),_c('div',{staticClass:"content-5 f-f-sans",style:({
            'font-size': this.pageLang === 'zh' ? '1.903vw' : _vm.none,
          })},[_c('p',[_vm._v(_vm._s(_vm.$t("message.culture.content-2")))])])]),_c('div',{staticClass:"col-1",style:({
          'margin-top': this.pageLang === 'zh' ? '7.247vw' : _vm.none,
        })},[_c('div',{staticClass:"container-5 container-5-low"},[_c('p',{staticClass:"title-5",style:({
              'font-size': this.pageLang === 'zh' ? '2.196vw' : _vm.none,
              'letter-spacing': this.pageLang === 'zh' ? '0.132vw' : _vm.none,
            })},[_vm._v(" "+_vm._s(_vm.$t("message.culture.mission"))+" ")])]),_c('div',{staticClass:"content-5 f-f-sans",style:({
            'font-size': this.pageLang === 'zh' ? '1.903vw' : _vm.none,
          })},[_c('p',[_vm._v(_vm._s(_vm.$t("message.culture.content-1")))])])])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }