<template>
  <div class="contact" v-if="this.$store.state.showContectUs">
    <div class="title-2">
      <p>Contact Us</p>
    </div>
    <div style="height:2.6rem"></div>
    <div class="content">
      <div class="left">
        <div class="row-1 title">
          YOUR NAME
        </div>
        <input @focus="onName" @focusout='offName' class="input-1" type="text" v-model="name">
        <div class="row-2 title">
          YOUR EMAIL
        </div>
        <input @focus="onEmail" @focusout='offEmail' type="text" v-model="email">
        <div class="row-3 title">
          YOUR COUNTRY
        </div>
        <select name="" id="" class="countries">
          <option value="1">Singapore</option>
          <option value="2">China</option>
          <option value="3">Malaysia</option>
        </select>
      </div>
      <div class="right">
        <div  class="row-1 title">
          MESSAGE
        </div>
        <textarea @focus="onMessage" @focusout='offMessage' class="message" v-model="message"></textarea>
        <div class="send-btn">SEND</div>
      </div>
    </div>

    
  </div>
</template>

<script>
export default {
  data(){
    return{
      name: 'Type Here',
      email: 'Type Here',
      message: 'Type Something...',
    }
  },
  methods: {
    onName(){
      if(this.name === 'Type Here'){
        this.name = ''
      }
    },
    offName(){
      if(this.name === ''){
        this.name = 'Type Here'
      }
      
    },
    onEmail(){
      if(this.email === 'Type Here'){
        this.email = ''
      }
    },
    offEmail(){
      if(this.email === ''){
        this.email = 'Type Here'
      }
    },
    onMessage(){
      if(this.message === 'Type Something...'){
        this.message = ''
      }
    },
    offMessage(){
      if(this.message === ''){
        this.message = 'Type Something...'
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.contact {
  background-color: #6E8EB5;
  //height: 45.1rem;
  padding: 7.523rem 23.1rem 12.8rem 23.1rem;

  .title-2 {
    text-align: center;
    color: var(--unnamed-color-000000);
    font: normal normal bold 2.1rem Raleway;
    letter-spacing: 0;
    letter-spacing: 0.164rem;
    color: white;
  }
  .content{
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    //background-color: red;
    width : 100%;
    min-height : 10rem;
    .title{
      font-size: 1rem;
      font-family: Arial;
      font-weight: 800;
      color: white;
    }
    .send-btn{
      position: absolute;
      right: 0;
      width: 12rem;
      height: 4rem;
      background-color: #033463;
      color: white;
      font-weight: 800;
      border-radius: 0.4rem;
      text-align: center;
      line-height: 4rem;
      cursor: pointer;
      font-size: 1.4rem;

    }

    input{
      height: 4.8rem;
      border-radius: 0.4rem;
      border: 0px;
      width: 39.4rem;
      margin-top: 0.8rem;
      font-size: 1.4rem;
      color: rgba(0, 52, 99, .5);
      padding-left: 1.6rem;
    }

    .left{
      .row-2{
        margin-top: 2.4rem;
      }
      .row-3{
        margin-top: 2.3rem;
      }
      .countries{
        height: 4.8rem;
        width: 41rem;
        padding-left: 1.6rem;
        margin-top: 0.8rem;
        font-size: 1.4rem;
        color: rgba(0, 52, 99, .5);
        border-radius: 0.4rem;
      }
    }
    .right{
      .message{
        width: 39.4rem;
        height: 19.2rem;
        font-size: 1.4rem;
        color: rgba(0, 52, 99, .5);
        border-radius: 0.4rem;
        padding-left: 1.6rem;
        margin-top: 0.8rem;
      }
    }
  }

 
}
</style>