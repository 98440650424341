<template>
  <div class="home">
    <Intro />
    <News />
    <About />
    <Gallary />
    <Cultures />
    <Contact />
  </div>
</template>

<script>
import Intro from "./components/Intro.vue";
import Gallary from "./components/Gallary.vue";
import News from "./components/News.vue";
import About from "./components/About.vue";
import Contact from "./components/Contact.vue";
import Cultures from "./components/Cultures.vue";

export default {
  data: function () {
    return {
      //content: "",
      selected: 1,
      mouseover: false,
      email: "Your email address…",
      text: "  Leave your message here…",
      scrollTop: 0,
      newsPageCount: 2,
      showContact: this.$store.state.showContectUs,
    };
  },

  components: {
    Intro,
    Gallary,
    News,
    About,
    Contact,
    Cultures,
  },

  created() {},

  methods: {
    goto(where) {
      this.$router.push("/" + where);
    },

    clearContent: function (content) {
      if (content === "email" && this.email === "Your email address…") {
        this.email = null;
      } else if (
        content === "text" &&
        this.text === "  Leave your message here…"
      ) {
        this.text = null;
      }
    },

    resetContent: function (content) {
      if (this.email === "") {
        this.email = null;
      }

      if (this.text === "") {
        this.text = null;
      }

      if (content === "email" && this.email === null) {
        this.email = "Your email address…";
      }

      if (content === "text" && this.text === null) {
        this.text = "  Leave your message here…";
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@font-face {
  font-family: "Avenir";
  src: url("../../assets/custom-font/Avenir Book.ttf");
}
@mixin styling($unit-size) {
  .home {
    width: 100%;
    //min-height: 100%;
    //padding: 14rem 14rem 0 14rem;

    .fade-enter-active {
      transition: opacity 0.3s;
      transition-delay: 200ms;
    }
    .fade-leave-active {
      transition: opacity 0.3s;
      transition-delay: 100ms;
    }
    .fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
      opacity: 0;
    }

    @media screen and (max-width: 768px) {
      .container-1 {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        align-items: center;
      }
    }
  }
}

@media (max-width: 100vw) {
  @include styling($unit-size: 100vw/1366);
}
</style>
